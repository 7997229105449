<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Help</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="container">

        <p>This Raro Guide map gives an address for every location on Rarotonga. The best way to understand this addressing system is to think of a clock.</p>

        <p>
          Rarotonga is sliced up into sectors radiating out from the center to the reef where each sector is numbered between 1 and 1200.
          Then the island is divided into rings from the reef and into the center of the island where each ring named according to the ordered letters of the Cook Islands maori alphabet, i.e., "A", "E", "I",..."Vi", "Vo", "Vu".
          The combination of the sectors and rings generate a grid of blocks with unique addresses for every location on Rarotonga.
        </p>

        <img src="assets/icon/icon.png" />

        <p>
          By using the relationship with a clock we can easily tell where an address is on the island.
          For example, 300 (3-
          o'clock) is east, around Avana, 600 (6-o'clock) is south, around Vaimaanga,
          900 (9-o'clock) is around Arorangi in the west, and 1200 (12-o'clock) is north, around Avatiu.
        </p>

        <p><b>Send Feedback:</b>Please send your feedback to duane.malcolm@gmail.com.</p>

        <h2>Sub-Blocks</h2>

        <p>
          Each block can be divided into a smaller grid of blocks where each sub-block is given a letter (a-z) from the English alphabet.
          For example, if the 934 Orongo Blk was subdivided, a sub-block that is labeled "f" would have a full and unique address of 934f Orongo Blk.
        </p>

        <p>
          This level 1 sub-block can be further subdivided into smaller level-2 subblocks and labelled with the letters "a" to "z".
          Continuing on the example above, a level-2 sub-block may be labelled "c" which will have a full and unique address of 934fc Orongo Blk.
        </p>

        <p>Blocks can be subdivided down more levels but three levels (L0, L1, L2) gives enough resolution to address most locations:
          <ul>
          <li>L0: the top level block is around 25x25m. These are suitable for house addresses.</li>
          <li>L1: the level 1 sub-block is around 5x5m. These are suitable for house addresses where two houses share a block, driveways, delivery locations, etc...</li>
          <li>L2: the level 2 sub-block is around 1x1m. These are suitable for utilities, e.g., power poles, telephone posts, power meters.</li>
          </ul>
        </p>


        <h2>Fun Stats</h2>

        <p>The distances between each hour 2.67km along Ara Tapu. This can give you a good estimate of the distance between two addresses.</p>

        <p>Given the equal 2.67km distance between each hour along Ara Tapu, you can guess which way around the island is the shortest path between two addresses.</p>

        <p>There are 108,000 blocks, about 2.7 million L1 sub-blocks, and about 67 million L2 sub-blocks. The land area of Rarotonga 67.2 km<sup>2</sup> or 67.2 million m<sup>2</sup>.</p>


      </div>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';


export default  {
  name: 'HelpTab',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage },



}
</script>

<style scoped>

.container {
  width: 720px;
  max-width: 100%;

  align-content: center;
  text-align: justify;
  font-family: serif;

  position: absolute;
  padding: 2em;
  padding-bottom: 96px;
}

.container p {
  font-size: 16px;
  line-height: 22px;

  color: #6e0000;

  margin: 0;
  padding-bottom: 1em;
}

</style>