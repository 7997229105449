
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';


export default  {
  name: 'HelpTab',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage },



}
